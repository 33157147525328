import React from "react";
import { graphql, PageProps } from "gatsby";

import { Container } from "@util/standard";
import { Query } from "@graphql-types";
import Components from "../components/component";

interface Props extends PageProps {
  data: Query;
}

const KitchenSink = ({ data }: Props) => {
  const { sanityKitchenSink } = data;
  if (sanityKitchenSink == null) return null;

  const { sink } = sanityKitchenSink;

  return (
    <Container flexDirection="column" justifyContent="center">
      {sink?.map(block => {
        if (block == null) return null;

        return <Components key={block._key} data={block} />;
      })}
    </Container>
  );
};

export const query = graphql`
  query kitchenSinkQuery {
    sanityKitchenSink {
      sink {
        ... on SanityLink {
          ...sanityLink
        }
        ... on SanityVideo {
          ...sanityVideo
        }
        ... on SanityBlockContent {
          ...sanityBlockContent
        }
        ... on SanityColumnBlock {
          ...sanityColumnBlock
        }
        ... on SanityHotspots {
          ...sanityHotspots
        }
      }
    }
  }
`;

export default KitchenSink;
